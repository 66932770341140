import React, { useState } from "react";
import { Button, TextField, Typography, Box } from '@mui/material';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import routes from '../routes';

/**
 * SignupForm Component
 * @param {Object} props
 * @param {Function} props.onBack - Handler to return to main auth options
 * @param {Function} props.onSignupSuccess - Handler called after successful signup
 */
const SignupForm = ({ onBack}) => {
  const { handleLogin } = useAuth();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  /**
   * Initiates first conversation after successful signup
   * @param {string} customerId - The ID of the newly registered customer
   */
  const handleStartConversation = async (customerId) => {
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/start_conversation`, {
        customer_id: customerId,
      },
      { withCredentials: true }
    );
      console.log("Conversation started for customer_id:", customerId);
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };

  /**
   * Handles the signup form submission
   */
  const handleSignup = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/auth/signup`, {
        name,
        email,
        password,
      },
      { withCredentials: true }
    );

      if (response.status === 200) {
        const customerId = response.data.customer_id;
        
        await handleLogin(email, password);
        
        await handleStartConversation(customerId);
        
        // if (onSignupSuccess) {
        //   onSignupSuccess();
        // }
        
        navigate(routes.chat);
      }
    } catch (error) {
      setMessage(`Signup error: ${error.response?.data?.detail || 'An error occurred.'}`);
    }
  };

  return (
    <Box mt={4} bgcolor="#f1f0e8" p={4} borderRadius={3}>
      <TextField
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        label="Email address"
        variant="outlined"
        fullWidth
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        label="Password"
        type="password"
        variant="outlined"
        fullWidth
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        variant="contained"
        fullWidth
        style={{ marginTop: '20px', backgroundColor: '#2EB872' }}
        onClick={handleSignup}
      >
        Sign Up
      </Button>
      {message && (
        <Typography color="error" style={{ marginTop: '10px' }}>
          {message}
        </Typography>
      )}
      <Button
        variant="text"
        onClick={onBack}
        style={{ marginTop: '10px' }}
      >
        Back
      </Button>
    </Box>
  );
};

export default SignupForm;
