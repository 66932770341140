import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //make a variable for the cookie presence
  const [isCookiePresent, setIsCookiePresent] = useState(false);
  const [customerId, setCustomerId] = useState(null);


  const checkAuthStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/auth/me`,
        { withCredentials: true }
      );
      
      console.log("response from /me api: ", response.data)
      
      //set the cookie presence
      setIsCookiePresent(response.data.is_cookie_present);

      console.log('is_cookie_present value:', response.data.is_cookie_present);

      if (response.data.is_cookie_present) {
        setCustomerId(response.data.customer_id);
        
        // Fetch chat history only if we have a customer_id
        const chatHistoryResponse = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}/chats/${response.data.customer_id}`
        );

        if (chatHistoryResponse.status === 200) {
          console.log('Chat history fetched again, storing in localStorage');
          localStorage.setItem('chatHistory', JSON.stringify(chatHistoryResponse.data));
          // Dispatch a custom event to notify components
          window.dispatchEvent(new Event('chatHistoryUpdated'));
        }
      } else {
        setCustomerId(null);
      }
    } catch (error) {
      console.error('Error in checkAuthStatus:', error);
    }
  };

   // Check auth status when tab becomes visible or user returns
   useEffect(() => {
    // Initial check
    checkAuthStatus();

    // Check when tab becomes visible
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkAuthStatus();
      }
    };

    // Check when window regains focus
    const handleFocus = () => {
      checkAuthStatus();
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
    };
  }, []);

  // Add this useEffect to monitor isCookiePresent changes
  useEffect(() => {
    console.log('isCookiePresent updated to:', isCookiePresent);
  }, [isCookiePresent]);

  const handleLogin = async (email, password) => {
    console.log('handleLogin called');
    setIsLoading(true);
    setError(null);

    try {
      const loginResponse = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/login`,
        { email, password },
        { withCredentials: true }
      );

      if (loginResponse.status === 200 && loginResponse.data.customer_id) {
        setCustomerId(loginResponse.data.customer_id);
        
        
        return loginResponse.data;
      }
    } catch (error) {
      setError(error.response?.data?.detail || 'Login failed. Please check your credentials.');
      throw error;
    } finally {
      setIsLoading(false);
      checkAuthStatus();
    }
  };

  // Initial auth check on mount: try deleting this part and see what happens.
  useEffect(() => {
    checkAuthStatus();
  }, []);

  const handleLogout = async () => {
    try {
      console.log('starting Logging out...');
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/logout`,
        {},
        { withCredentials: true }
      );
      setCustomerId(null);
      setIsCookiePresent(false);
    } catch (error) {
      console.error('AuthContext: Logout error:', error);
      throw error;
    } 
  };

  return (
    <AuthContext.Provider value={{ 
      customerId, 
      handleLogin,
      handleLogout, 
      checkAuthStatus,
      isLoading, 
      error,
      isCookiePresent
    }}>
      {children}
    </AuthContext.Provider>
  );
};
