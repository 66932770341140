import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Switch, useLocation,useNavigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';

//import useAuth hook
import { useAuth } from './hooks/useAuth';

import Chat from './components/Chat';
import Login from './components/Login';
import Signup from './components/Signup';
import LandingPage from './components/LandingPage';
import routes from './routes';

import ReactGA from 'react-ga4';

import Terms from './components/Terms';
import PrivacyPolicy from './components/PrivacyPolicy';

// Your Google Analytics Measurement ID
const MEASUREMENT_ID = "G-SYM1MR3BTG";

function App() {

  // Initialize Google Analytics only once
  useEffect(() => {
    ReactGA.initialize(MEASUREMENT_ID);
  }, []);




  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

function AppContent() {
  const location = useLocation(); // Moved useLocation here
  const navigate = useNavigate();
  const { isCookiePresent, isLoading } = useAuth();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]); // Track page views on every route change


  useEffect(() => {
    if (isCookiePresent) {
      navigate(routes.chat);
    }
  }, [isCookiePresent, navigate]);

  if (isLoading) {
    return null; // or a loading spinner
  }

  return (
    <Routes>
      <Route path={routes.home} element={<LandingPage />} />
      <Route path={routes.login} element={<Login />} />
      <Route path={routes.chat} element={<Chat />} />
      <Route path={routes.terms} element={<Terms />} />
      <Route path={routes.privacyPolicy} element={<PrivacyPolicy />} />
    </Routes>
  );
}

export default App;
