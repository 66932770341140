import React from 'react';
import { Container, Typography, Box } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md">
      <Box py={4}>
        <Typography variant="h4" gutterBottom>
          Privacy Policy
        </Typography>
        {/* Add your privacy policy content here */}
        <Typography paragraph>
          
Privacy policy
We at ayna, Inc. (“ayna,” “we,” “us,” or “our”) have created this privacy policy (this “Privacy Policy”) because we know that you care about how information you provide to us is used and shared. This Privacy Policy relates to the information collection and use practices of ayna in connection with our App.
By clicking “I AGREE,” or otherwise manifesting your asset to the Privacy Policy and accompanying Terms of Use, when you sign up to access and use the App, you acknowledge that you have read, understood and agree to be legally bound by the terms of this Privacy Policy and the accompanying Terms of Use.
Capitalized terms not defined in this Privacy Policy shall have the meaning set forth in our Terms of Use.
The Information We Collect and/or Receive
In the course of operating the App, and/or interacting with you, we will collect (and/or receive) the following types of information. You authorize us to collect and/or receive such information.
1. Contact Information.
If you would like to use the services of Ayna, you will need to create an account on the ayna.co.in website or in the future possibly download the App from the Apple, Android or any other app store through which the App is made available to you. If you contact us for support via email or customer support contact form in any other manner, you will have to provide your email address (collectively, the “Contact Information”). The Contact Information is used to provide the requested service or information.
2. Mental Health Related Information.
Your Mental Health Related Information is transmitted to ayna in an anonymous way to be analyzed by our AI models and send back information to your device. Ayna stores this information in anonymous manner on its platform for improved experience of the customers. 
3. Other Information.
In addition to the Contact Information, we may collect additional information (the “Other Information”). Such Other Information may include:
From Your Activity. Information that we automatically collect when you use the App, including, without limitation:
IP addresses, browser type and language, referring and exit pages and URLs, date and time, amount of time spent on particular pages, what sections of the App you visit, etc.;
Information about a mobile device, including universally unique ID (“UUID”), App type and version (e.g., iOS or Android), carrier and country location, hardware and processor information (storage, chip speed, camera resolution, NFC enabled, and network type (WiFi, 2G, 3G, 4G); and
Activity and usage information occurring via the App, including tagging data, favorites, preferences, session lengths; and similar data.
From Cookies. We collect information using “cookie” technology. Cookies are small packets of data that a website stores on your computer’s or mobile device’s hard drive so that your computer will “remember” information about your visit. We use session cookies, which expire once you close your web browser, to help us collect Other Information and to enhance your experience using the App. If you do not want us to place a cookie on your hard drive, you may be able to turn that feature off on your computer or mobile device. Please consult your Internet browser’s documentation for information on how to do this and how to delete persistent cookies. However, if you decide not to accept cookies from us, the App may not function properly.
Third-Party Analytics. We may use one or more third-party analytics services to evaluate your use of the App, compile reports on activity (based on their collection of IP addresses, Internet service provider, browser type, operating system and language, referring and exit pages and URLs, data and time, amount of time spent on particular pages, what sections of the App you visit, number of links clicked while on the App, search terms and other similar usage data), and analyze performance metrics. These third parties use cookies and other technologies to help analyze and provide us the data. By accessing and using the App, you consent to the processing of data about you by these analytics providers in the manner and for the purposes set out in this Privacy Policy. For more information on these third parties, including how to opt out from certain data collection, please visit the sites below. Please be advised that if you opt out of any service, you may not be able to use the full functionality of the App.

For Mixpanel, please visit: https://mixpanel.com/legal/privacy-policy/
For Google Firebase, please visit: https://firebase.google.com/support/privacy
How We Use and Share the Information
You authorize us to use the Contact Information, Mental Health Related Information, and Other Information (collectively, the “Information”) to provide you the App, solicit your feedback, inform you about our products and services and those of our third-party marketing partners, and to improve our App.
You also authorize us to use and/or share your Information as described below.
Agents, Providers and Related Third Parties.We may engage other companies and individuals to perform certain business-related functions on our behalf. Examples may include providing technical assistance, order fulfillment, customer service, and marketing assistance. These other companies will have access to the Information only as necessary to perform their functions and to the extent permitted by law. We may also share your Information with any of our parent companies, subsidiaries, or other companies under common control with us.
Aggregated Information. In an ongoing effort to better understand our users and our App, we might analyze your Information in aggregate form in order to operate, maintain, manage, and improve the App. This aggregate information does not identify you personally. We may share this aggregate data with our affiliates, agents, and business partners. We may also disclose aggregated user statistics in order to describe our App to current and prospective business partners and to other third parties for other lawful purposes.
Business Transfers. As we develop our businesses, we might sell or buy businesses or assets. In the event of a corporate sale, merger, reorganization, sale of assets, dissolution, or similar event, your Information may be part of the transferred assets.
Legal Requirements. To the extent permitted by law, we may also disclose your Information: (i) when required by law, court order, or other government or law enforcement authority or regulatory agency; or (ii) whenever we believe that disclosing such information is necessary or advisable, for example, to protect the rights, property, or safety of ayna or others.
Accessing and Modifying Information and Communication Preferences
If you have provided us any personal information, you may access, remove, review, and/or make changes to the same by contacting us at care.ayna@gmail.com. In addition, you may manage your receipt of marketing and non-transactional communications by clicking on the “Unsubscribe” link located on the bottom of any ayna marketing e-mail. We will use commercially reasonable efforts to process such requests in a timely manner. You should be aware, however, that it is not always possible to completely remove or modify information in our subscription databases.
How We Protect the Information
We take commercially reasonable steps to protect your Information from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. Please understand, however, that no security system is impenetrable. We cannot guarantee the security of our databases or the databases of the third parties with which we may share such information, nor can we guarantee that the information you supply will not be intercepted while being transmitted over the Internet. In particular, e-mail sent to us may not be secure, and you should therefore take special care in deciding what information you send to us via e-mail.
External Sites
The App may, from time to time, contain links to external websites. ayna encourages you to review the privacy and security policies of any externally linked websites that may be accessed through the App. ayna assumes no responsibility or liability for the information collection and disclosure practices of any external websites that a user can access through the App. Please check the privacy policies of these external websites before you submit any personal information to them.
Children’s Information
We do not knowingly collect personal information from children under the age of 18 through the App. If you are under 18, please do not give us any personal information. We encourage parents and legal guardians to monitor their children’s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide personal information through the App without their permission. If you have reason to believe that a child under the age of 18 has provided personal information to us, please contact us, and we will endeavor to delete that information from our databases.
Important Notice to Non-Indian Residents
The App and its servers are operated in India. If you are located outside of India, please be aware that any information you provide to us maybe transferred to, processed, maintained, and used on computers, servers, and systems located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. If you are located outside the India and choose to use the App, you do so at your own risk.
If you are a resident of the European Union (“EU”), United Kingdom, Lichtenstein, Norway, or Iceland, you may have additional rights under the EU General Data Protection Regulation (the “GDPR”) with respect to your Personal Data, as outlined in our GDPR Addendum (the “Addendum”) at the end of this Privacy Policy.
Notice to California Residents
Pursuant to Section 1798.83 of the California Civil Code, residents of California have the right to obtain certain information about the types of personal information that companies with whom they have an established business relationship (and that are not otherwise exempt) have shared with third parties for direct marketing purposes during the preceding calendar year, including the names and addresses of those third parties, and examples of the types of services or products marketed by those third parties. If you wish to submit a request pursuant to Section 1798.83, please contact us via email at care.ayna@gmail.com.
DO NOT TRACK
ayna does not monitor, recognize, or honor any opt-out or do not track mechanisms, including general web browser “Do Not Track” settings and/or signals.
Notice to Nevada Residents
If you are a resident of Nevada, you have the right to opt-out of the sale of certain personal information to third parties. You can exercise this right by contacting us at care.ayna@gmail.com with the subject line “Nevada Do Not Sell Request” and providing us with your name and the email address associated with your account. Please note that we do not currently sell your personal information as sales are defined in Nevada Revised Statutes Chapter 603A.
Changes to This Privacy Policy
This Privacy Policy is effective as of the date stated at the top of this Privacy Policy. We may change this Privacy Policy from time to time with or without notice to you. Any such changes will be posted on the App. By accessing and/or using the App after we make any such changes to this Privacy Policy, you are deemed to have accepted such changes. Please be aware that, to the extent permitted by applicable law, our use of your information is governed by the Privacy Policy in effect at the time we collect the Information. Please refer back to this Privacy Policy on a regular basis.
How to Contact Us
If you have any questions about this Privacy Policy or to report a privacy issue, please contact us in one of the following ways:
Email: care.ayna@gmail.com
Or write to us at:

Ayna
No.21, I A Cross, I Main, 
Ashwatnagar Extension,
RMV II Stage, Bengaluru 560094

GDPR Addendum
Effective as of: November [●], 2022

If you are a resident of the European Union (“EU”), United Kingdom, Lichtenstein, Norway, or Iceland, you may have additional rights under the EU General Data Protection Regulation (the “GDPR”) and the UK-GDPR with respect to your Personal Data, as outlined in this GDPR Addendum (the “Addendum”). For this Addendum, we use the terms “Personal Data” and “processing” as they are defined in the GDPR, but “Personal Data” generally means information that can be used to identify a person, and “processing” generally refers to actions that can be performed on data such as its collection, use, storage or disclosure. ayna will be the controller of your Personal Data processed in connection with the Services. Where applicable, this Addendum is intended to supplement, and not replace, our Privacy Policy. If there are any conflicts between the Addendum and the other parts of the Privacy Policy, the policy or portion that is more protective of Personal Data shall control to the extent of such conflict.
Our Contact Information
Ayna is located at No.21, I A Cross, I Main, Ashwatnagar Extension,RMV II Stage, Bengaluru 560094 . Please use this address or, preferably, reach out to us at care.ayna@gmail.com for any questions, complaints, or requests regarding this GDPR Notice; please include the subject line “GDPR Request.”

Types of Personal Data we Collect
We currently collect and otherwise process the kinds of Personal Data listed above in Section I of the Privacy Policy.

How we get the Personal Data and why we have it

We receive the Personal Data in the ways and for the purposes listed above in Sections I and II of the Privacy Policy. Under the GDPR, the lawful bases we rely on for processing this information are listed in the table below:
Direct Marketing: Sending email marketing to our Users based on their consent.

Provision of App: We will process all Personal Data as necessary for the performance of contracts to which our Users are a party, such as our Terms of Use and Privacy Policy, in order to provide our App.

Audience Measurement: Pursuant to their consent, our use of analytics on our App to understand how our Users interact with the App.

General Business Development: Our legitimate interest in furthering relationships with our Users, ensuring customer satisfaction, and answering inquiries.
Information Security: Our web servers will log your IP address and other information (e.g., browser information, operating system, request date/time, user agent string, referral and exiting URL) in order to maintain an audit log of activities performed. We use this information pursuant to our legitimate interests in tracking App usage, combating DDOS or other attacks, and removing or defending against malicious visitors to the App.
Compliance with Applicable Law: We will process Personal Data as needed to fulfill our legal obligations, such as our obligations relating to tax, accounting, and finance.

Recipients of your Personal Data
ayna personnel shall receive and process your Personal Data for the purposes described above. Personal Data is also disclosed to the following categories of US-based recipients to effectuate the purposes described above: hosting providers, analytics providers, customer support services, and marketing and transactional email services.

How we store and protect your Personal Data
We use reasonable administrative, technical, and physical safeguards to protect your Personal Data from loss, misuse, and unauthorized access, disclosure, alteration, or destruction, for which we take into account the nature of the Personal Data, its processing, and the threats posed to it. Unfortunately, no data transmission or storage system can be guaranteed to be secure at all times. If you have reason to believe that your interaction with us is no longer secure, please immediately notify us via email at care.ayna@gmail.com.

We retain your Personal Data for as long as needed to fulfill the purposes for which we obtained it, and delete it if you request it or such data is no longer relevant. In some cases, we may have to retain data to comply with our legal obligations (e.g., accounting, finance, and tax obligations). We will only keep your Personal Data for as long as allowed or required by law.



Your data protection rights
Under data protection law, you have rights, which include:

Right of access: You have the right to ask us for copies of your Personal Data.

Right to rectification: You have the right to ask us to rectify Personal Data you think is inaccurate. You also have the right to ask us to complete information you think is incomplete.

Right to erasure: You have the right to ask us to erase your Personal Data in certain circumstances.

Right to restriction of processing: You have the right to ask us to restrict the processing of your Personal Data in certain circumstances.

Right to object to processing: You have the the right to object to the processing of your Personal Data in certain circumstances.

Right to data portability: You have the right to ask that we transfer the Personal Data you gave us to another organisation, or to you, in certain circumstances.

Right to withdraw consent: You have the right to withdraw your consent where consent is used as the legal basis for processing your Personal Data.
Objecting to Legitimate Interest/Direct Marketing: You may object to Personal Data processed pursuant to our legitimate interest. In such case, we will no longer process your Personal Data unless we can demonstrate appropriate, overriding legitimate grounds for the processing or if needed for the establishment, exercise, or defense of legal claims. You may also object at any time to processing of your Personal Data for direct marketing purposes by clicking “Unsubscribe” within an automated marketing email or by submitting your request to care.ayna@gmail.com with the subject line “GDPR Request.” In such case, your Personal Data will no longer be used for that purpose.
You are not required to pay any charge for exercising your rights. If you make a request, we have one month to respond to you. Please contact us at care.ayna@gmail.com with the subject line “GDPR Request” if you wish to make a request.

Transfer of Personal Data to the United States
To transfer your Personal Data to the United States, we rely on the following, as appropriate: (i) appropriate Standard Contractual Clauses to ensure adequate protection for your personal data, (ii) your consent; (iii) the derogations under Article 49(1)(b) and (1)(c) for performance of our contracts with you or to execute a contract in your interest with another entity/person, or (iv) any other valid transfer mechanisms, appropriate safeguards or exceptions under applicable law in effect at the time of such transfer.

How to complain

If you have any concerns about our use of your Personal Data, you can make a complaint to us at care.ayna@gmail.com with the subject line “GDPR Request.”

You also have the right to lodge a complaint about the processing of your personal data with a supervisory authority of the European state where you work or live or where any alleged infringement of data protection laws occurred. A list of most of the supervisory authorities can be found here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
Disclosure to public authorities

Ayna may be required to disclose Personal Data in response to lawful requests by public authorities, including for the purpose of meeting national security or law enforcement requirements. We may also disclose Personal Data to other third parties when compelled to do so by government authorities or required by law or regulation including, but not limited to, in response to court orders and subpoenas.
Corporate restructuring

In the event of a merger, reorganization, dissolution, or similar corporate event, or the sale of all or substantially all of our assets, the information that we have collected, including Personal Data, may be transferred to the surviving or acquiring entity. All such transfers shall be subject to our commitments with respect to the privacy and confidentiality of such Personal Data as set forth in this Addendum.


Updates to this Addendum

If, in the future, we intend to process your Personal Data for a purpose other than that which it was collected, we will provide you with information on that purpose and any other relevant information at a reasonable time prior to such processing. After such time, the relevant information relating to such processing activity will be revised or added appropriately within this Addendum, and the “Effective Date” at the top of this page will be updated accordingly.
        </Typography>
      </Box>
    </Container>
  );
};

export default PrivacyPolicy; 