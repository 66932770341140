const routes = {
  home: '/',
  chat: '/chat',
  subscription: '/subscription',
  settings: '/settings',
  googleCallback: '/auth/google/callback',
  terms: '/terms',
  privacyPolicy: '/privacy-policy'
};

export default routes;
