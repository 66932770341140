import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

const TypewriterText = ({ text, variant = "h4", sx = {} }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText(prev => prev + text[currentIndex]);
        setCurrentIndex(prev => prev + 1);
      }, 50); // Adjust speed of typing here (50ms between each character)

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, text]);

  return (
    <Typography
      variant={variant}
      sx={{
        textAlign: 'center',
        mb: 4,
        color: '#3C392B',
        fontWeight: 500,
        minHeight: '3rem', // Prevents layout shift
        ...sx,
      }}
    >
      {displayedText}
      {currentIndex < text.length && (
        <span 
          style={{ 
            borderRight: '2px solid #3C392B',
            animation: 'blink 1s step-end infinite',
          }}
        >
        </span>
      )}
    </Typography>
  );
};

export default TypewriterText; 