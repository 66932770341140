import React, { useState, useEffect, useRef } from 'react';
import { Button, TextField, Typography, Box, IconButton, Container } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import routes from '../routes';
import './Chat.css';
import TypewriterText from './TypewriterText';

const Chat = () => {
  const { customerId, isCookiePresent, handleLogout } = useAuth();
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const navigate = useNavigate();
  const messagesEndRef = useRef(null);
  const chatMessagesRef = useRef(null);
  const [stickyDate, setStickyDate] = useState(null);

  useEffect(() => {
    console.log('tracking cookie value in chat.js useEffect: ', isCookiePresent);
    if (isCookiePresent) {
      navigate(routes.chat);
      loadChatHistory();
    }
    else {
      navigate(routes.home);
      return;
    }
  }, [isCookiePresent, navigate]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const chatContainer = chatMessagesRef.current;
    if (chatContainer) {
      const handleScroll = () => {
        const dateHeaders = chatContainer.querySelectorAll('.date-header');
        let visibleHeader = null;

        for (const header of dateHeaders) {
          const rect = header.getBoundingClientRect();
          if (rect.top <= 60 && rect.bottom > 60) {
            visibleHeader = header;
            break;
          }
        }

        setStickyDate(visibleHeader ? visibleHeader.textContent : null);
      };

      chatContainer.addEventListener('scroll', handleScroll);
      return () => chatContainer.removeEventListener('scroll', handleScroll);
    }
  }, [messages]);

  useEffect(() => {
    const handleChatHistoryUpdate = () => {
      console.log('Chat history updated, reloading...');
      loadChatHistory();
    };

    window.addEventListener('chatHistoryUpdated', handleChatHistoryUpdate);

    return () => {
      window.removeEventListener('chatHistoryUpdated', handleChatHistoryUpdate);
    };
  }, []);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const loadChatHistory = () => {
    const storedChatHistory = localStorage.getItem('chatHistory');
    if (storedChatHistory) {
      const normalizedMessages = JSON.parse(storedChatHistory).map(msg => ({
        role: msg.role,
        content: msg.message || msg.content,
        timestamp: new Date(msg.timestamp),
        date: new Date(msg.timestamp).toISOString().split('T')[0]
      }));
      setMessages(normalizedMessages);
    }
  };

  const handleSendMessage = async () => {
    if (!customerId || !userInput.trim()) return;

    const currentMessages = messages.filter(msg => !msg.isExample);
    const userMessage = { role: 'user', content: userInput, timestamp: new Date(), date: new Date().toISOString().split('T')[0] };
    
    setMessages(prevMessages => {
      const updatedMessages = [...currentMessages, userMessage];
      localStorage.setItem('chatHistory', JSON.stringify(updatedMessages));
      return updatedMessages;
    });
    setUserInput('');
    setIsTyping(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/continue_conversation`,
        { customer_id: customerId, query: userInput },
        { withCredentials: true }
      );

      if (response.status === 200) {
        const assistantResponse = { role: 'assistant', content: response.data.response, timestamp: new Date(), date: new Date().toISOString().split('T')[0] };
        setMessages(prevMessages => {
          const updatedMessages = [...prevMessages, assistantResponse];
          localStorage.setItem('chatHistory', JSON.stringify(updatedMessages));
          return updatedMessages;
        });
      }
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsTyping(false);
    }
  };




  return (
    <Container maxWidth="md" sx={{ height: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      <Box 
        sx={{ 
          position: 'fixed',
          top: 16,
          right: { xs: 24, sm: 32, md: 48 },
          zIndex: 1000,
        }}
      >
        <Button 
          onClick={handleLogout} 
          variant="contained"
          sx={{
            backgroundColor: '#c65e41',
            fontSize: { xs: '1.2rem', sm: '1.4rem' },
            padding: { xs: '10px 24px', sm: '12px 32px' },
            '&:hover': { backgroundColor: '#b54e31' }
          }}
        >
          Logout
        </Button>
      </Box>

      <Box
        ref={chatMessagesRef}
        className="chat-messages"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          p: 2,
          position: 'relative',
          width: '100%',
          maxWidth: '800px',
          mt: 7,
          px: { xs: 2, sm: 3 },
        }}
      >
        {messages.length > 0 && messages.map((msg, index) => (
          <Box
            key={index}
            display="flex"
            justifyContent={msg.role === 'user' ? 'flex-end' : 'flex-start'}
            mb={1}
            sx={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}
          >
            <Box
              bgcolor={msg.role === 'user' ? '#c65e41' : '#f1f0e8'}
              p={2}
              borderRadius={2}
              maxWidth="70%"
              sx={{ wordBreak: 'break-word' }}
            >
              <Typography 
                variant="body1" 
                sx={{ 
                  color: msg.role === 'user' ? '#ffffff' : '#3C392B',
                  fontFamily: msg.role === 'user' ? '"Fira Code", monospace' : 'Helvetica, Arial, sans-serif',
                }}
              >
                {msg.content}
              </Typography>
            </Box>
          </Box>
        ))}
        
        {isTyping && (
          <Box
            display="flex"
            justifyContent="flex-start"
            mb={1}
            sx={{ width: '100%', maxWidth: '800px', margin: '0 auto' }}
          >
            <Box
              bgcolor="#f1f0e8"
              p={2}
              borderRadius={2}
              className="typing-indicator"
            >
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </Box>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>
      
      <Box
        sx={{
          position: messages.length === 0 ? 'absolute' : 'relative',
          top: messages.length === 0 ? '40%' : 'auto',
          left: messages.length === 0 ? '50%' : 'auto',
          transform: messages.length === 0 ? 'translate(-50%, -50%)' : 'none',
          width: '100%',
          maxWidth: '800px',
          mb: 2,
        }}
      >
        {messages.length === 0 && (
          <TypewriterText 
            text="👋 Welcome to Ayna! I'm your mental health companion."
            sx={{
              textAlign: 'center',
              mb: 4,
              color: '#3C392B',
              fontWeight: 500,
            }}
          />
        )}

        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSendMessage();
          }}
          sx={{
            display: 'flex',
            gap: 1,
            p: 2,
            backgroundColor: 'white',
            borderRadius: 2,
            boxShadow: messages ? '0 4px 12px rgba(0,0,0,0.1)' : 'none',
          }}
        >
          <TextField
            fullWidth
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Type your message here..."
            variant="outlined"
            multiline
            maxRows={15}
            sx={{
              maxHeight: 200,  // Set maximum height
              overflow: 'auto',  // Enable scroll when content overflows
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                overflow: 'auto',  // Ensure overflow within the TextField
              },
            }}
          />
          <IconButton type="submit" color="primary">
            <SendIcon />
          </IconButton>
        </Box>

        {messages.length === 0 && (
          <Box
            sx={{
              mt: 4,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              sx={{
                backgroundColor: '#f8f7f2',
                p: 2,
                borderRadius: 2,
                border: '1px dashed #3C392B',
                maxWidth: '80%',
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#f1f0e8' }
              }}
            >
              You can share anything that's on your mind. I'm here to listen and support you.
            </Typography>
            <Typography
              variant="body1"
              sx={{
                backgroundColor: '#f8f7f2',
                p: 2,
                borderRadius: 2,
                border: '1px dashed #3C392B',
                maxWidth: '80%',
                textAlign: 'center',
                cursor: 'pointer',
                '&:hover': { backgroundColor: '#f1f0e8' }
              }}
            >
              Try starting with "How are you feeling today?" or "I'd like to talk about..."
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default Chat;
