import React from 'react';
import { Box, Typography } from '@mui/material';

const ChatBubble = ({ text, isUser, isVisible, sx }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: isUser ? 'flex-end' : 'flex-start',
        mb: 0.25,
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.5s ease-in-out',
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: '45%',
          p: 0.75,
          borderRadius: 1,
          backgroundColor: isUser ? '#c65e41' : '#FFFDDD',
          color: isUser ? 'white' : 'black',
          ...sx,
        }}
      >
        <Typography 
          variant="body2"
          sx={{ 
            fontSize: '0.8rem',
            lineHeight: 1,
          }}
        >
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default ChatBubble;
